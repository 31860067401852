.language-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .dropdown-btn:hover {
    background-color: #0056b3;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    min-width: 150px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .language-dropdown:hover .dropdown-content {
    display: block;
  }
  
  .dropdown-content button {
    width: 100%;
    padding: 10px 15px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  .dropdown-content button:hover {
    background-color: #f1f1f1;
  }
  