.email-services h2 {
    margin-bottom: 30px;
    font-size: 24px;
    color: #333;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Максимум 4 карточки в строке */
    justify-content: center;
}

/* Карточка сервиса */
.service-card {
    position: relative;
    width: 100%;
    padding-top: 100%; /* Квадратная карточка */
    border: 1px solid #ddd;
    border-radius: 8px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-card > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.placeholder-logo {
    width: 80px; /* Размеры логотипа */
    height: 80px;
    display: flex;
    align-items: center; /* Центровка по вертикали */
    justify-content: center; /* Центровка по горизонтали */
    background-color: #ccc; /* Фон для пустого логотипа */
    border-radius: 8px;
    margin-bottom: 10px;
    overflow: hidden; /* Обрезаем содержимое, если оно выходит за границы */
}

.service-logo {
    max-width: 100%; /* Логотип не будет выходить за пределы */
    max-height: 100%;
    object-fit: contain; /* Поддерживаем пропорции изображения */
}

.service-name {
    font-size: 16px;
    color: #333;
}

.service-price {
    font-size: 14px;
    color: #777;
}

/* Адаптация для мобильных устройств */
@media (max-width: 768px) {
    .services-grid {
        display: flex;
        flex-direction: column; /* Элементы в колонку */
        gap: 10px;
    }

    .service-card {
        display: flex; /* Отображаем карточки как строки */
        flex-direction: row; /* Горизонтальное расположение содержимого */
        padding: 0%;
        justify-content: start;
        width: auto;

    }
    .service-card > div {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .placeholder-logo {
        width: 50px;
        height: 50px; /* Уменьшаем размер иконки */
        margin-right: 10px; /* Отступ справа от логотипа */
    }

    .service-name {
        font-size: 16px;
        text-align: left;
    }

    .service-price {
        font-size: 14px;
        color: #333;
        margin-left: 10px; /* Отступ слева от цены */
        white-space: nowrap; /* Запрещаем перенос цены */
    }
    .email-services.section-container
    {
        overflow: auto;
        height: 300px;
    }
}


