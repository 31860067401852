/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Section Container */
.section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

/* Features Section */
.homepage-features {
  padding: 40px;
}

.features-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 20px;
  justify-content: center;
}

.feature-item {
  padding: 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-item h3 {
  font-size: 16px;
  color: #333;
}

/* Footer Section */
.homepage-footer {
  background-color: #343a40;
  color: white;
  text-align: center;
  padding: 20px;
  font-size: 14px;
}

.homepage-footer p {
  margin: 0;
}

/* Support Section */
.homepage-support {
  background-color: #f9f9f9;
  padding: 40px;
  text-align: center;
}

.homepage-support h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.homepage-support p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.support-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.support-image {
  width: 150px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Reviews Section */
.homepage-reviews {
  padding: 40px 20px;
  text-align: center;
}

.homepage-reviews h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
}

.reviews-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
}

.review-item {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.review-item p {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
  line-height: 1.5;
}

.review-item h4 {
  font-size: 14px;
  color: #333;
  text-align: right;
}

/* Why Choose Us Section */
.homepage-why-choose-us {
  padding: 40px 20px;
  text-align: center;
}

.homepage-why-choose-us h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 30px;
}

.why-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-bottom: 10px;
}

.why-list::-webkit-scrollbar {
  display: none;
}

.why-item {
  min-width: 250px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.header-content-inner
{
  display: flex;
  align-items: center;
  gap:30px;
}

/* Start Now Button */
.start-now-btn {
  background-color: #007bff;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: none;
}

.start-now-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.start-now-btn:active {
  transform: scale(1);
}
.header-image
{
  width: 80%;
}
.header-text
{
  flex: 2;
}
.header-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('D:\xampp\htdocs\PYTHON\anymessage\public\images\letter.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  /* width: 100%; */
  height: 400px;
  flex: 2;
}

.header-image {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
}

.email-overlay {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.from-service
{
  display: flex;
  align-self: start;
  flex-direction: column;
  width: 50%;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 10px;
}
.underlined-service{
  font-weight: bold;
}
.from-service-main{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.email-fields {
  display: flex;
  align-self: end;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 10px;
  width: 50%;
}
.to-email-main{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.to-email-tip{
  text-align: start;
}
.to-email-info{
  font-style: italic;
  font-size: 15px;
  text-align: start;
}

.from-service input,
.to-email select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.send-email-btn {
  align-self: flex-end;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-email-btn:hover {
  background-color: #218838;
}


@media (max-width: 1000px) {

  .from-service, .email-fields{
    width: auto;
  }
}
@media (max-width: 768px) {
  .header-content-inner
  {
    flex-direction: column-reverse;
  }
  .header-image-wrapper{
    display: none!important;
  }
  .start-now-btn{
    display: block!important;
  }

}

@media (min-width: 768px) {

  .why-list {
      flex-wrap: wrap;
      justify-content: center;
      overflow-x: visible;
  }

  .why-item {
      flex: 1 1 calc(33.333% - 20px);
      min-width: auto;
      max-width: 300px;
  }
}

@media (min-width: 1200px) {
  .why-item {
      flex: 1 1 calc(25% - 20px);
  }
}
