/* Задний фон попапа */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Контент попапа */
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;
  }
  
  /* Кнопка закрытия */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
  
  /* Стиль кнопки-ссылки */
  .link-btn {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  /* Стили формы */
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  /* Кнопки формы */
  .btn {
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #055964;
    color: white;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  .password-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .password-input-wrapper input {
    flex: 1;
    padding-right: 40px; /* Отступ для кнопки */
  }
  
  .toggle-password {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #007bff;
  }
  
  .toggle-password:hover {
    color: #0056b3;
  }
  .popup-content {
    position: relative;
    background: white;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    max-width: 90%;
    text-align: center;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    display: none;
  }
  
  .social-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5px;
  }
  
  .social-icon img {
    width: 40px;
    height: 40px;
  }
  
  .social-icon p {
    font-size: 14px;
    color: #333;
    margin: 0;
  }

  .popup-content {
  position: relative;
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  text-align: center;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
}

.auth-error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: center; /* ✅ Центрируем текст */
  background-color: #ffe6e6; /* ✅ Светло-красный фон */
  padding: 5px;
  border-radius: 5px;
}
.password-success-message {
  color: green;
  font-size: 14px;
  margin-top: 5px;
}

.password-error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.auth-error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
  background-color: #ffe6e6;
  padding: 5px;
  border-radius: 5px;
}
.password-icon {
  font-size: 18px;
  width: 25px; /* ✅ Фиксированная ширина, чтобы не сдвигало input */
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ⚪ Нейтральная иконка (по умолчанию) */
.password-icon.neutral {
  color: gray;
  visibility: visible; /* ✅ Гарантируем, что иконка всегда видна */
}

/* ✅ Зеленая галочка */
.password-icon.success {
  color: green;
}

/* ❌ Красный крестик */
.password-icon.error {
  color: red;
}
