/* Сбрасываем отступы и добавляем глобальные стили */
body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

/* Шапка сайта */
.homepage-header {
    background: linear-gradient(90deg, #055964, #ff4d4d); /* Синий в красный градиент */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    position: relative;
    z-index: 50; /* Убедитесь, что шапка выше overlay */
}

/* Логотип */
.logo h1 {
    font-size: 24px;
    margin: 0;
    color: #fff;
}

/* Контейнер для навигации и кнопок */
.header-controls {
    display: flex;
    align-items: center;
    gap: 15px;
}

.header-controls a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.header-controls .btn {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-wrap: nowrap;
}

.header-controls .btn-primary {
    background-color: #055964;
    color: white;
}

.header-controls .btn-primary:hover {
    background-color: #0056b3;
}

/* Бургер-кнопка */
.burger-menu {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    display: none; /* Скрываем на больших экранах */
    z-index: 100; /* Убедитесь, что кнопка выше overlay */
}

/* Выпадающее меню */
/* .dropdown-menu {
    position: absolute;
    top: 60px;
    right: 10px;
    width: 200px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    transform: translateY(-20px);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    z-index: 101; 
} */

.dropdown-menu.open {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.dropdown-menu a {
    text-decoration: none;
    padding: 10px 20px;
    color: #333;
    display: block;
}

.dropdown-menu a:hover {
    background-color: #f1f1f1;
}

/* Языковые опции */
.dropdown-menu .language-options {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    text-align: center;
}

.dropdown-menu .language-options a {
    text-decoration: none;
    color: #007bff;
    font-size: 16px;
}

.dropdown-menu .language-options a:hover {
    color: #0056b3;
}

/* Затемняющий слой */
.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.menu-overlay.active {
    opacity: 1;
    visibility: visible;
}

/* Адаптивные стили */
@media (min-width: 769px) {
    .header-controls {
        display: flex;
    }

    .burger-menu {
        display: none; /* Скрываем бургер на больших экранах */
    }
}

@media (max-width: 768px) {
    .header-controls a {
        display: none; /* Скрываем ссылки на мобильных устройствах */
    }
   .btn-secondary.full-screen{
        display: none;
    }

    .burger-menu {
        display: block; /* Показываем бургер на мобильных устройствах */
    }

    /* .dropdown-menu {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 10px 0;
    } */
    .header-logo {
        visibility: hidden;
        position: relative;
        width: 30px;
    }
    .header-logo:after {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        content: "AM";
    }
}
/* Выпадающее меню */
.dropdown-menu {
    position: absolute;
    top: 60px; /* Отступ от верхней части шапки */
    left: 0; /* Выравнивание по левому краю */
    width: 100%; /* Занимает всю ширину экрана */
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    transform: translateY(-20px);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    z-index: 101; /* Убедитесь, что меню выше overlay */
}

.burger-menu.open .dropdown-menu {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

/* Ссылки внутри меню */
.dropdown-menu a {
    text-decoration: none;
    padding: 15px;
    color: #333;
    display: block;
    text-align: center; /* Центрируем текст */
}

.dropdown-menu a:hover {
    background-color: #f1f1f1;
}

/* Языковые опции */
.dropdown-menu .language-options {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    text-align: center;
}
.skeleton {
    display: inline-block;
    height: 1em;
    width: 4em;
    background: #e0e0e0;
    border-radius: 4px;
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: 200px 0;
    }
  }
  .balance-add-btn{
    padding: 10px 15px;
    text-wrap: nowrap;
    color: #fff;
    font-weight: 600;
    background: #055964;
    border-radius: 8px;
    border: none;
    font-size: 16px;
  }

/* Контейнер для сдвоенной кнопки */
.dual-button {
 
    display: flex;
    align-items: center;
    border: 2px solid #055964;
    border-radius: 8px;
    overflow: hidden; /* Убираем скругление между кнопками */
  }
  
  /* Левая часть – профиль */
  .profile-btn {
    background: #055964;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
  }
  
  .profile-btn:hover {
    background: #0056b3;
  }
  
  /* Правая часть – баланс */
  .balance-btn {
    background: white;
    color: #0056b3;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
/* Контейнер для сдвоенной кнопки */
.dual-button {
    display: flex;
    align-items: stretch; /* Растягиваем вложенные кнопки */
    border: 2px solid #007bff;
    border-radius: 8px;
    overflow: hidden; /* Убираем обрезку краёв */
    height: 40px; /* Фиксированная высота */
    overflow: visible;
  }
  
  /* Левая часть – профиль */
  .profile-btn {
    background: #0056b3;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    flex-grow: 1; /* Растягиваем кнопку на всю высоту */
    height: 100%; /* Теперь кнопка заполняет высоту */
  }
  
  .profile-btn:hover {
    background: #0056b3;
  }
  
  /* Правая часть – баланс */
  .balance-btn {
    background: white;
    color: #0056b3;
    border: none;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    flex-grow: 2; /* Делаем кнопку баланса шире */
    height: 100%; /* Теперь кнопка заполняет высоту */
    text-wrap: nowrap;
  }
  
  .balance-btn:hover {
    background: #f0f0f0;
  }

  /* Дропдаун */
.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
    z-index: 100;
    width: 120px;
  }
  
  .dropdown-content a {
    display: block;
    padding: 10px;
    color: #333;
    text-decoration: none;
  }
  
  .dropdown-content a:hover {
    background: #f0f0f0;
  }

  .exit-mobile{
    color: #fff;
    background: red;
  }
  

  .language-switcher {
    position: relative;
    display: inline-block;
    margin-left: 15px;
  }
  
  .lang-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .lang-btn:hover {
    opacity: 0.7;
  }
  
  .lang-dropdown {
    position: absolute;
    top: 50px;
    left: 0;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    overflow: hidden;
    z-index: 100;
    min-width: 120px;
  }
  
  .lang-option {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background: white;
    cursor: pointer;
    text-align: left;
  }
  
  .lang-option:hover {
    background: #f0f0f0;
  }
  
  
  