/* Затемнение фона при открытии попапа */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Затемнение */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Поверх всех элементов */
}

/* Окно попапа */
.popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 90%;
    animation: fadeIn 0.3s ease-in-out;
}

/* Анимация появления попапа */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Кнопка закрытия */
.close-btn {
    background: none;
    border: none;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

/* Поле ввода кода */
.code-input {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

/* Основная кнопка */
.btn-primary {
    background: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0;
    transition: background 0.2s ease-in-out;
}

.btn-primary:hover {
    background: #218838;
}

/* Кнопка "Отправить повторно" */
.btn-secondary-request {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px 0;
    transition: background 0.2s ease-in-out;
}

.btn-secondary-request:hover {
    background: #0056b3;
}

/* Отключенная кнопка */
.btn-secondary-request:disabled {
    background: #ccc;
    cursor: not-allowed;
}
.success-message {
    color: green;
    font-weight: bold;
    margin-bottom: 10px;
}

.error-message-activation {
    color: red;
    font-weight: bold;
}
.activation-buttons{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
