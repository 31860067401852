/* PersonalCabinet.css */

.personal-cabinet {
    font-family: Arial, sans-serif;
  }
  
  .user-balance {
    margin-bottom: 30px;
    text-align: center;
  }
  
  .balance-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;

  }
  .preset-buttons{
    display: flex;
    justify-content: space-between;
  }
  
  .balance-container select {
    padding: 5px 10px;
    font-size: 16px;
  }
  
  .buy-email select {
    padding: 5px 10px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  
  .popup-content input[type="number"] {
    padding: 10px 15px;
    font-size: 16px; /* Увеличиваем размер шрифта */
    width: 100%; /* Полная ширина контейнера */
    max-width: 400px; /* Максимальная ширина */
    border: 1px solid #ddd; /* Добавляем границу */
    border-radius: 5px; /* Закругленные углы */
    margin-bottom: 15px; /* Отступ снизу */
    box-sizing: border-box; /* Учитываем padding */
}

  
  .buy-email h2 {
    margin-bottom: 15px;
  }
  
  .buy-email form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .email-icon
  {
    margin-right: 20px;
  }


  .checkbox-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .checkbox-wrap input {
    margin-right: 5px;
  }
  

  
  .received-emails {
    margin-bottom: 30px;
  }
  
  .received-emails h2 {
    margin-bottom: 15px;
  }
  
  .email-table {
    border-collapse: collapse;
    width: 100%;
    overflow: auto;
  }
  
  .table-header,
  .table-row {
    display: grid;
    display: flex;
    justify-content: space-between;
    grid-template-columns: 1fr 2fr 3fr 2fr 1fr;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  
  .table-header {
    font-weight: bold;
    background-color: #e9ecef;
  }
  
  .table-row:nth-child(even) {
    /* background-color: #f1f1f1; */
    background-color: #fff;
  }
  
  .table-row div,
  .table-header div {
    padding: 5px;
  }
  /* .mobile-details div
  {
    width: 45%;
  } */
  .email-cell {
    /* Ваши стили */
    width:35%
  }
  .email-cell .copy-btn{
    margin-right: 20px;
  }
 .service-cell, .site-cell, .mobile-actions {
    /* Ваши стили */
    width:25%
  }
  .id-cell,.status-cell{
    width: 15%;
  }
  .mobile-details > div:second-child {
    /* Ваши стили */
    width:25%
  }
  
  .table-row button {
    padding: 5px 10px;
    font-size: 14px;
    margin-right: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* .table-row button:hover {
    background-color: #0056b3;
  } */

  .balance-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .balance-container .btn {
    margin-left: 10px;
  }

  
  .balance-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .balance-popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .balance-popup-content h2 {
    margin-bottom: 20px;
  }
  
  .balance-popup-content button {
    display: block;
    margin-bottom: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: #007bff;
    color: #fff;
  }
  
  .balance-popup-content .btn-secondary {
    background: #6c757d;
  }
  
  .balance-popup-input {
    padding: 10px 15px;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    color: #333!important;
    background: none!important;
    border: none!important;
  }
  
  .close-btn:hover {
    color: #ff0000; /* Цвет при наведении */
  }
  .email-cell, .service-cell, .id-cell {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

  }
  .email-cell span
  {
    word-wrap: break-word;
    width: 100%;
  }
  
  
  .email-cell:hover span, .service-cell:hover, .id-cell:hover{
    text-decoration: underline;
  }
  
  .copy-btn {
    background: none!important;
    border: none;
    cursor: pointer;
  }
  
  .copy-btn img {
    width: 16px;
    height: 16px;
  }
  
  .copy-btn span {
    font-size: 14px;
    color: #888;
  }
  
  
  .copy-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
  }
  
  .copy-btn:hover .copy-icon {
    filter: brightness(0.8); /* Затемнение при наведении */
  }
  .message-control-btn
  {
    height: 100%;
    display: flex;
    flex-direction: row;
  }
  .delete-btn
  {
    background-color: #dc3545!important;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 5px;
  }
  
  .pagination-btn {
    padding: 5px 10px;
    font-size: 14px;
    color: #1e7c89;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-btn.active {
    background-color: #1e7c89;
    color: white;
  }
  .pagination-btn:hover
  {
    background-color: #1e7c89;
    color: #fff;
  }
  
  .pagination-btn:disabled {
    background-color: #f1f1f1;
    color: #ccc;
    cursor: not-allowed;
  }  

  .email-purchase-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
  

  
  /* button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 14px;
  } */
  

  
  .purchase-actions label,
  .purchase-actions p {
    margin: 10px 0;
  }
  
  #total-price {
    font-weight: bold;
    color: #333;
  }



  
  .clear-input-btn:hover {
    color: #ff0000;
  }
/* .clear-input-btn:active {
  transform: translateY(-50%) scale(0.9);
} */
  /* Общий стиль для таблицы */
.table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

/* Скрытие дополнительных данных на больших экранах */
@media (min-width: 768px) {
  .mobile-details {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: start;
  }

  .mobile-actions {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
  }
}


/* Для мобильной версии */
@media (max-width: 768px) {
  .table-header{
    display: none;
  }
  .mobile-actions button{
    font-size: 30px;
    flex: auto;
  }

  .email-purchase-container
  {
    display: flex;
    flex-direction: column;
  }
  .table-row {
    display: flex;
    flex-direction: row;
    padding: 10px;
    gap: 10px;
  }
  .delete-btn, .show-btn
  {
    height: 100%;
  }
  .mobile-details {
    max-width: 50%;
    flex-wrap: wrap;
  }
  .message-control-btn
  {
    display: flex;
    flex-direction: row;
  }
  .message-control-btn button
  {
    flex: 1;
    min-width: auto;
  }

  .mobile-details div {
    margin-bottom: 5px;
    width: 100%;
    
  }

  .mobile-actions {
    display: flex;
    flex: auto;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .mobile-actions div {
    font-size: 14px;
    color: #555;
    height: 50%;
  }

  .copy-btn {
    margin-left: 5px;
  }



  /* button {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
  } */

  button:hover {
    opacity: 0.9;
  }
}
.delete-btn {
  background-color: #ff4d4d;
}
.show-btn {
  background-color: #1e7c89;
}
.show-btn:hover{
  background-color: #055964;
}
.update-btn {
  background-color: #3911d8;
}
/* Скелет-загрузка */
.skeleton {
  background: #f6f7f8;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: pulse 1.5s infinite;
}

.skeleton-box {
  width: 80px;
  height: 20px;
  background: #e0e0e0;
  border-radius: 5px;
  animation: pulse 1.5s infinite;
}

.skeleton-box.small {
  width: 40px;
  height: 20px;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}

/* Пустое поле */
.table-row.empty {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  color: #888;
  font-size: 16px;
}
.refresh-status-btn{
  text-wrap: nowrap;
  padding: 0px!important;
}
.status-wait{
  padding: 0px!important;
}

.copy-container {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.copy-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.copy-icon {
  width: 16px;
  height: 16px;
  transition: opacity 0.3s ease-in-out; /* ✅ Добавляем плавное изменение */
}
