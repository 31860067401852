
.buy-email label {
    margin-bottom: 10px;
    width: 80%;
}
.service-selection label, .email-type-selection label
{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popular-services, .filter-buttons
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 20px;
}
  



  /* .buy-email button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  } */

   .filter-buttons button, .popular-services button
   {
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid #000000;
    color: black;
    border-radius: 5px;
    cursor: pointer;
   }
   .popular-services button:hover, .filter-buttons button:hover{
    background-color: #1e7c89;
    color: #fff;
    /* font-weight: bold; */
   }
   .buy-button
   {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #1e7c89;
    border-radius: 5px;
    width: 100%;
    border: none;
    color: white;
    cursor: pointer;
   }
   .buy-button:hover{
    background-color: #055964;
   }
   .buy-email button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
   .service-group
   {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
   }
   .service-selection,
   .email-type-selection,
   .purchase-actions {
     flex: 1;
     border: 1px solid #ddd;
     border-radius: 8px;
     padding: 20px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   }
   .email-type-selection.disabled,.purchase-actions.disabled {
    background: repeating-linear-gradient(
      -45deg,
      #f0f0f0,
      #f0f0f0 10px,
      #e0e0e0 10px,
      #e0e0e0 20px
    ); /* Серый заштрихованный фон */
    opacity: 0.6; /* Делаем блок полупрозрачным */
  }
  
   .purchase-actions{
    gap: 20px;
    display: flex;
    max-height: 60px;
   }
   .service-selection
   {
     display: flex;
     flex-direction: column;
     align-items: center;
   }

   .quantity-control {
    display: flex;
    align-items: center;
  }
  .quantity-control button{
    flex: 1;
  }
  
  .quantity-btn {
    width: 30px;
    height: 30px;
    background-color: #1e7c89;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    height: 100%;
  }
  .quantity-btn:hover{
    background-color: #055964;
  }
  
  .quantity-btn:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .quantity-input {
    width: 60px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 0 5px;
    height: 100%;
    flex: 1;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none; /* Для браузеров на основе WebKit */
    margin: 0; /* Убираем лишние отступы */
  }
  /* input[type="number"] {
    -moz-appearance: textfield;
  } */
  .purchase-actions {
    text-align: center;
  }
  .purchase-actions h3{
    display: none;
  }
    /* Для стилизации выбранного типа почты */
  .email-types-scroll .selected {
    background-color: #1e7c89;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
  }

  .email-types-scroll {
    height: 200px; /* Установите желаемую высоту */
    overflow-y: auto; /* Включите вертикальный скролл */
    border: 1px solid #ddd; /* Добавьте границу для четкости */
    padding: 10px; /* Внутренний отступ для элементов */
    background-color: #f9f9f9; /* Фон для консистентности */
    background-color: #fff; /* Фон для консистентности */
    border-radius: 5px; /* Закругленные углы */
  }
  
  
  
  
  .email-types-scroll button {
    color: black;
    background: none;
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    text-align: left;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  

  .email-types-scroll {
    max-height: 100px;
    transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

/* Когда введен сайт, плавно увеличиваем высоту до 200px */
.email-types-scroll.expanded {
    max-height: 200px;
    opacity: 1;
}

    /* Блок информации о пользователе */
.user-info-bar {
  display: flex;
  align-items: center;
  gap: 30px;
  background: #f8f9fa;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Иконка пользователя */
.user-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
/* Заменяем иконку пользователя на серый прямоугольник */
.user-icon-placeholder {
    width: 40px;
    height: 40px;
    background: #ccc; /* Серый цвет */
    border-radius: 8px; /* Закругленные углы */
}

/* Контейнер данных пользователя */
.user-details {
  flex-grow: 1;
  margin-left: 10px;
}

.user-details p {
  margin: 10px 0;
  font-size: 14px;
}

/* Токен с кнопкой копирования */
.copy-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 5px;
}

/* Баланс и кнопка пополнения */
.balance-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-funds-btn {
  background: #28a745;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  /* transition: background 0.3s; */
}

.add-funds-btn:hover {
  background: #218838;
}
.service-selection-group{
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}
.no-emails-message{
  text-align: center;
}

@media (max-width: 768px) {
  .purchase-actions
  {
      display: flex;
  }
  .email-icon
  {
    margin: 0px;
    display: none;
  }
  .popular-services{
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
    padding: 10px;
   }
  .email-types-scroll button
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 20px;
    min-width: 80px;
    text-align: center;
    background: linear-gradient(135deg, #4A90E2, #9B59B6);
    color: #fff;
  }
  
  .email-types-scroll
  {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 20px;
    height: auto;
    max-height: auto!important;

  }
  .email-types-scroll.expanded{
    height: auto;
    max-height: auto;
  }
  } 

  .last-searched-btn {
    margin-left: 10px;
    color: white;
    border: none;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .last-searched-btn {
    border-color: #0056b3!important;
  }
  

  
  .input-container {
    position: relative;
    width: 100%;
  }
  
  .input-container input {
    width: 100%;
    padding-right: 30px; /* Оставляем место для кнопки */
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .clear-input-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #888;
  }

  .error-message {
    position: absolute;
    bottom: -80%; /* Ошибка показывается ниже поля, но не изменяет его размер */
    left: 0;
    color: red;
    font-size: 12px;
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  
  .shake {
    animation: shake 0.3s ease-in-out;
  }
  
  .input-error {
    border: 2px solid red;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  .clickable-balance{
    cursor: pointer;
  }

  /* Общий стиль кнопки */
.activate-btn {
  background-color: #ff3b3b; /* Красный цвет */
  text-align: center;
  color: white;
  font-weight: bold;
  padding: 12px 10px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  transition: background-color 0.3s ease-in-out, transform 0.2s;
  
  /* Параллелограмм */
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
}

/* Улучшенная анимация подрагивания + поворот */
@keyframes shake-rotate {
  0% { transform: rotate(0deg) translateX(0); }
  25% { transform: rotate(-2deg) translateX(-2px); }
  50% { transform: rotate(2deg) translateX(2px); }
  75% { transform: rotate(-1deg) translateX(-1px); }
  100% { transform: rotate(0deg) translateX(0); }
}

/* Применяем анимацию, если аккаунт не активирован */
.activate-btn.shake {
  animation: shake-rotate 1.5s infinite ease-in-out; /* Более плавное качание */
}

/* Эффект при наведении */
.activate-btn:hover {
  background-color: #d62c2c;
  transform: scale(1.05);
}

/* Эффект при нажатии */
.activate-btn:active {
  transform: scale(0.95);
}
